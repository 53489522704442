// Load jQuery from NPM
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import lozad from "lozad";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "./javascripts/ScrollSmoother";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import VenoBox from "venobox";

// import AssetCart from 'asset-cart';

// import './css/fromjs.sass'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
// console.log('hello');
// (function() {

// console.log('hello two');

//  })();
$(document).ready(function () {
  new VenoBox({
    selector: ".venobox",
    spinner: "wave",
    maxWidth: "90%",
  });

  var scrollElements = document.querySelectorAll("[data-scrollto]");

  for (var i = 0; i < scrollElements.length; i++) {
    scrollElements[i].addEventListener("click", function (e) {
      e.preventDefault();

      // Get the target element to scroll to
      var target = document.querySelector(this.getAttribute("data-scrollto"));

      // Use GSAP's scrollTo method
      gsap.to(window, {
        duration: 1, // specify the duration of the scroll here
        scrollTo: target,
        ease: "power3.inOut", // specify the easing function here
      });
    });
  }

  var tl = gsap.timeline();
  tl.from(".hero span", { duration: 0.6, opacity: 0, y: 90, stagger: 0.15 });
  // .to('.hero span.last', {duration: .15, color:"#00A543"});

  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();

  const phases = gsap.utils.toArray(".complete-journey .phase");
  const panels = gsap.utils.toArray(".complete-journey .panel");
  const cardWrap = gsap.utils.toArray(".see-in-action .card-wrap");
  const menuOptions = gsap.utils.toArray(".see-in-action .menu-option");
  const menuOptionsContent = gsap.utils.toArray(
    ".see-in-action .content-overlay-content .content"
  );

  const completeJourney = gsap.utils.toArray("a.complete-journey-btn");
  const sections = gsap.utils.toArray(".section");
  const topNav = document.querySelector(".menu-bar");
  let navHeight = topNav.offsetHeight;

  const nextButtons = document.querySelectorAll(".next-btn");
  nextButtons.forEach((button) => {
    button.addEventListener("click", function () {
      console.log("next button click");
      const currentContent = this.closest(
        ".content-overlay-content"
      ).querySelector(".content.active");
      const nextContent = currentContent.nextElementSibling;
      const parentOverlay = this.closest(".content-overlay");

      if (nextContent && nextContent.classList.contains("content")) {
        // Find the corresponding next menu option
        const nextMenuOption = parentOverlay.querySelector(
          `.menu-option.${nextContent.classList[1]}`
        );

        if (nextMenuOption) {
          // Trigger click on the next menu option
          nextMenuOption.click();
        } else {
        }

        // Hide the Next button if it's the last content
        // if (
        //   nextContent.nextElementSibling &&
        //   nextContent.nextElementSibling.classList.contains("content")
        // ) {
        //   this.classList.add("active-btn");
        // } else {
        //   this.classList.remove("active-btn");
        // }
      } else {
        console.log("CURRENT CONTENT")
        console.log(currentContent)
        if(currentContent.classList.contains('retail')) {
          document.querySelector(".tab-container.retail .tab.healthcare").click()
        } else if(currentContent.classList.contains('healthcare')) {
          document.querySelector(".tab-container.healthcare .tab.manufacturing").click()
        } else if(currentContent.classList.contains('manufacturing')) {
          document.querySelector(".tab-container.manufacturing .tab.education").click()

        } else if(currentContent.classList.contains('education')) {
          document.querySelector(".tab-container.education .tab.retail").click()
        }
      }
    });
  });

  function hideNextBtn(nextBtn) {
    nextBtn.classList.remove("active-btn");
  }
  function showNextBtn(nextBtn) {
    nextBtn.classList.add("active-btn");
  }

  phases.forEach((phase, i) => {
    const panelTrigger = gsap.timeline({
      scrollTrigger: {
        // markers:true,
        trigger: phase,
        start: "top 75%",
        end: "top -100%",
        toggleActions: "play none none reset",
        onEnter: (self) => {
          phase.classList.add("mobile-active");
        },
        onLeave: () => {
          phase.classList.remove("mobile-active");
        },
        onEnterBack: () => {
          phase.classList.add("mobile-active");
        },
        onLeaveBack: () => {
          phase.classList.remove("mobile-active");
        },
      },
    });
  });

  // cardWrap.forEach((card, i) => {
  //   const cardWrapTrigger = gsap.timeline({
  //     scrollTrigger: {
  //       // markers:true,
  //       trigger: card,
  //       start: "top 70%",
  //       end: "top top",
  //       toggleActions: "play none none reset",
  //       onEnter: (self) => {
  //         card.classList.add("mobile-reveal");
  //       },
  //       onLeave: () => {},
  //       onEnterBack: () => {},
  //       onLeaveBack: () => {},
  //     },
  //   });
  // });
  // Uncomment for interactive
  menuOptions.forEach((option, i) => {
    option.addEventListener("click", (event) => {
      menuOptions.forEach((menuOption, i) => {
        if (menuOption.classList.contains("active")) {
          menuOption.classList.remove("active");
        }
      });

      option.classList.add("active");

      if (option.classList.contains("one")) {
        if (option.classList.contains("retail")) {
          document.querySelector(".content.one.retail").classList.add("active");
          document
            .querySelector(".next-btn.retail")
            .classList.add("active-btn");
          document
            .querySelector(".content.two.retail")
            .classList.remove("active");
          document
            .querySelector(".content.three.retail")
            .classList.remove("active");
        } else if (option.classList.contains("healthcare")) {
          document
            .querySelector(".next-btn.healthcare")
            .classList.add("active-btn");
          document
            .querySelector(".content.one.healthcare")
            .classList.add("active");
          document
            .querySelector(".content.two.healthcare")
            .classList.remove("active");
          document
            .querySelector(".content.three.healthcare")
            .classList.remove("active");
        } else if (option.classList.contains("education")) {
          document
            .querySelector(".next-btn.education")
            .classList.add("active-btn");
          document
            .querySelector(".content.one.education")
            .classList.add("active");
          document
            .querySelector(".content.two.education")
            .classList.remove("active");
          document
            .querySelector(".content.three.education")
            .classList.remove("active");
        } else if (option.classList.contains("manufacturing")) {
          document
            .querySelector(".next-btn.manufacturing")
            .classList.add("active-btn");
          document
            .querySelector(".content.one.manufacturing")
            .classList.add("active");
          document
            .querySelector(".content.two.manufacturing")
            .classList.remove("active");
          document
            .querySelector(".content.three.manufacturing")
            .classList.remove("active");
        }
      } else if (option.classList.contains("two")) {
        if (option.classList.contains("retail")) {
          document
            .querySelector(".next-btn.retail")
            .classList.add("active-btn");
          document
            .querySelector(".content.one.retail")
            .classList.remove("active");
          document.querySelector(".content.two.retail").classList.add("active");
          document
            .querySelector(".content.three.retail")
            .classList.remove("active");
        } else if (option.classList.contains("healthcare")) {
          document
            .querySelector(".next-btn.healthcare")
            .classList.add("active-btn");
          document
            .querySelector(".content.one.healthcare")
            .classList.remove("active");
          document
            .querySelector(".content.two.healthcare")
            .classList.add("active");
          document
            .querySelector(".content.three.healthcare")
            .classList.remove("active");
        } else if (option.classList.contains("education")) {
          document
            .querySelector(".next-btn.education")
            .classList.add("active-btn");

          document
            .querySelector(".content.one.education")
            .classList.remove("active");
          document
            .querySelector(".content.two.education")
            .classList.add("active");
          document
            .querySelector(".content.three.education")
            .classList.remove("active");
        } else if (option.classList.contains("manufacturing")) {
          document
            .querySelector(".next-btn.manufacturing")
            .classList.add("active-btn");
          document
            .querySelector(".content.one.manufacturing")
            .classList.remove("active");
          document
            .querySelector(".content.two.manufacturing")
            .classList.add("active");
          document
            .querySelector(".content.three.manufacturing")
            .classList.remove("active");
        }
      } else if (option.classList.contains("three")) {
        if (option.classList.contains("retail")) {
          document
            .querySelector(".next-btn.retail")
            .classList.add("active-btn");
          document
            .querySelector(".content.one.retail")
            .classList.remove("active");
          document
            .querySelector(".content.two.retail")
            .classList.remove("active");
          document
            .querySelector(".content.three.retail")
            .classList.add("active");
        } else if (option.classList.contains("healthcare")) {
          document
            .querySelector(".next-btn.healthcare")
            .classList.add("active-btn");

          document
            .querySelector(".content.one.healthcare")
            .classList.remove("active");
          document
            .querySelector(".content.two.healthcare")
            .classList.remove("active");
          document
            .querySelector(".content.three.healthcare")
            .classList.add("active");
        } else if (option.classList.contains("education")) {
          document
            .querySelector(".next-btn.education")
            .classList.add("active-btn");

          document
            .querySelector(".content.one.education")
            .classList.remove("active");
          document
            .querySelector(".content.two.education")
            .classList.remove("active");
          document
            .querySelector(".content.three.education")
            .classList.add("active");
        } else if (option.classList.contains("manufacturing")) {
          document
            .querySelector(".next-btn.manufacturing")
            .classList.add("active-btn");

          document
            .querySelector(".content.one.manufacturing")
            .classList.remove("active");
          document
            .querySelector(".content.two.manufacturing")
            .classList.remove("active");
          document
            .querySelector(".content.three.manufacturing")
            .classList.add("active");
        }
      }
    });
  });

  // Function to set initial GSAP properties
  function setInitialGSAP(className) {
    const baseSelector = `.content-overlay.${className}.desktop:not(.retail)`;
    gsap.set(baseSelector, { autoAlpha: 0, duration: 0 });
    gsap.set(`${baseSelector} .overlay-bg`, {
      xPercent: -135,
      autoAlpha: 1,
      duration: 0,
    });

    gsap.set(`${baseSelector} .menu-options`, {
      xPercent: -100,
      autoAlpha: 0,
      duration: 0,
    });
    gsap.set(`${baseSelector} .tab-container`, {
      xPercent: 100,
      autoAlpha: 0,
      duration: 0,
    });
    gsap.to(`${baseSelector} .content-overlay-content`, {
      autoAlpha: 0,
      x: 0,
      duration: 0,
    });
    gsap.set(`${baseSelector} .menu-options .menu-option.two`, {
      xPercent: -100,
      autoAlpha: 0,
      duration: 0,
    });
    gsap.set(`${baseSelector} .menu-options .menu-option.three`, {
      xPercent: -100,
      autoAlpha: 0,
      duration: 0,
    });
  }

  function handleCardClick(
    className,
    menuOptionsContent,
    menuOptions
  ) {
    const baseSelector = `.content-overlay.${className}.desktop`;
    const tl = gsap.timeline();

    // Hide all cards
    // cardWrap.forEach((c) => (c.style.opacity = "0"));

    tl.set(`${baseSelector} .overlay-bg`, {
      xPercent: -135,
      autoAlpha: 1,
      duration: 0,
    });

    // Start animation for the overlay
    tl.to(baseSelector, { autoAlpha: 1, ease: "power3.inOut", duration: 0.25 });

    // Animation for the overlay background
    tl.to(
      `${baseSelector} .overlay-bg`,
      {
        autoAlpha: 1,
        ease: "power3.inOut",
        xPercent: 0,
        duration: 0.5,
      },
      0
    );

    // Animation for menu options
    tl.to(
      `${baseSelector} .menu-options`,
      {
        autoAlpha: 1,
        ease: "power3.inOut",
        xPercent: 0,
        duration: 0.75,
      },
      0.2
    );

    // Animation for tab container
    tl.to(
      `${baseSelector} .tab-container`,
      {
        autoAlpha: 1,
        ease: "power3.inOut",
        xPercent: 0,
        duration: 0.75,
      },
      0.2
    );

    // Animation for each menu option
    tl.to(
      `${baseSelector} .menu-options .menu-option.two`,
      {
        autoAlpha: 1,
        ease: "power3.inOut",
        xPercent: 0,
        duration: 0.75,
      },
      0.3
    );

    tl.to(
      `${baseSelector} .menu-options .menu-option.three`,
      {
        autoAlpha: 1,
        ease: "power3.inOut",
        xPercent: 0,
        duration: 0.75,
      },
      0.5
    );

    // Animation for content overlay
    tl.to(
      `${baseSelector} .content-overlay-content`,
      {
        autoAlpha: 1,
        ease: "power3.inOut",
        x: 0,
        duration: 0.35,
        onComplete: () => {
          document
            .querySelector(`${baseSelector} .menu-options .menu-option.one`)
            .classList.add("active");
          document
            .querySelector(`${baseSelector} .content.one`)
            .classList.add("active");
          setTimeout(() => {
            document
              .querySelector(`${baseSelector} .next-btn`)
              .classList.add("active-btn");
          }, 500);
        },
      },
      0.8
    );
  }

  function handleTabSwitch(
    classNamePrev,
    newClassName,
    menuOptionsContent,
    menuOptions
  ) {
    const baseSelector = `.content-overlay.${classNamePrev}.desktop`;
    const newBaseSelector = `.content-overlay.${newClassName}.desktop`;
    if (document.querySelector(`${baseSelector} .tab-container .tab.${classNamePrev}`) !== document.querySelector(`${newBaseSelector} .tab-container .tab.${newClassName}`)) {

    console.log(classNamePrev)
    console.log()
    const tl = gsap.timeline();
    document
      .querySelectorAll(".tab")
      .forEach((tab) => (tab.style.pointerEvents = "none"));

    tl.to(
      `${baseSelector} .menu-options .menu-option.three`,
      { autoAlpha: 0, xPercent: -100, ease: "power3.inOut", duration: 0.75 },
      0
    );
    tl.to(
      `${baseSelector} .menu-options .menu-option.two`,
      { autoAlpha: 0, xPercent: -100, ease: "power3.inOut", duration: 0.75 },
      0
    );
    tl.to(
      `${baseSelector} .menu-options`,
      { autoAlpha: 0, xPercent: -100, duration: 0.75, ease: "power3.inOut" },
      0
    );
    tl.to(
      `${baseSelector} .overlay-bg`,
      { autoAlpha: 0, xPercent: -135, ease: "power3.inOut", duration: 0.4 },
      0
    );
    tl.to(
      `${baseSelector} .content-overlay-content`,
      {
        autoAlpha: 0,
        x: 0,
        ease: "power3.inOut",
        duration: 0.5,
        onComplete: () => {
          tl.to(baseSelector, {
            autoAlpha: 0,
            ease: "power3.inOut",
            duration: 0,
          });
        },
      },
      0
    );
    tl.set(`${newBaseSelector} .overlay-bg`, {
      xPercent: -135,
      autoAlpha: 1,
      duration: 0,
    });

    let mm = gsap.matchMedia();

    mm.add("(min-width: 801px)", () => {
      tl.to(`${baseSelector} .tab-container`, {
        xPercent: 0,
        autoAlpha: 1,
        duration: 0,
        onStart: () => {},
        onComplete:() => {
          console.log("NEW CLASS NAME")
          console.log(newClassName)
          console.log(`${newBaseSelector} .tab-container .tab.${newClassName}`)
          document.querySelector(`${baseSelector} .tab-container .tab.${classNamePrev}`).classList.remove("active")
          document.querySelector(`${newBaseSelector} .tab-container .tab.${newClassName}`).classList.add("active")
        }
      });
      tl.to(`${newBaseSelector} .tab-container`, {
        xPercent: 0,
        autoAlpha: 1,
        duration: 0,
      });
    });

    mm.add("(max-width: 800px)", () => {
      tl.to(`${baseSelector} .tab-container`, {
        xPercent: 0,
        autoAlpha: 1,
        duration: 0,
        onComplete: () => {
          tl.to(`${newBaseSelector} .tab-container`, {
            xPercent: 0,
            autoAlpha: 1,
            duration: 0,
          });
          console.log("NEW CLASS NAME")
          console.log(newClassName)
          console.log(`${newBaseSelector} .tab-container .tab.${newClassName}`)
          document.querySelector(`${baseSelector} .tab-container .tab.${classNamePrev}`).classList.remove("active")
          document.querySelector(`${newBaseSelector} .tab-container .tab.${newClassName}`).classList.add("active")

        },
      });
    });

    tl.to(newBaseSelector, {
      autoAlpha: 1,
      ease: "power3.inOut",
      duration: 0.2,
      onComplete: () => {
        tl.to(
          `${newBaseSelector} .overlay-bg`,
          { autoAlpha: 1, ease: "power3.inOut", xPercent: 0, duration: 0.5 },
          0
        );
        tl.to(
          `${newBaseSelector} .menu-options`,
          { autoAlpha: 1, ease: "power3.inOut", xPercent: 0, duration: 0.75 },
          0.2
        );
        tl.to(
          `${newBaseSelector} .menu-options .menu-option.two`,
          { autoAlpha: 1, ease: "power3.inOut", xPercent: 0, duration: 0.75 },
          0.3
        );
        tl.to(
          `${newBaseSelector} .menu-options .menu-option.three`,
          { autoAlpha: 1, ease: "power3.inOut", xPercent: 0, duration: 0.75 },
          0.5
        );
        document
          .querySelectorAll(`${baseSelector} .menu-option`)
          .forEach((el) => el.classList.remove("active"));
        document
          .querySelectorAll(`${baseSelector} .content`)
          .forEach((el) => el.classList.remove("active"));
        document
          .querySelector(`${baseSelector} .next-btn`)
          .classList.remove("active-btn");

        tl.to(
          `${newBaseSelector} .content-overlay-content`,
          {
            autoAlpha: 1,
            ease: "power3.inOut",
            x: 0,
            duration: 0.35,
            onComplete: () => {
              document
                .querySelector(
                  `${newBaseSelector} .menu-options .menu-option.one`
                )
                .classList.add("active");
              document
                .querySelector(`${newBaseSelector} .content.one`)
                .classList.add("active");
              setTimeout(() => {
                document
                  .querySelector(`${newBaseSelector} .next-btn`)
                  .classList.add("active-btn");
                // Re-enable all tabs
                document
                  .querySelectorAll(".tab")
                  .forEach((tab) => (tab.style.pointerEvents = ""));
              }, 500);
            },
          },
          1
        );
      },
    });
  }
}

  function handleCloseClick(
    className,
    cardWrap,
    menuOptionsContent,
    menuOptions
  ) {
    const baseSelector = `.content-overlay.${className}.desktop`;
    const tl2 = gsap.timeline();

    // Reverse animations for menu options and overlay content
    tl2.to(
      `${baseSelector} .menu-options .menu-option.three`,
      { autoAlpha: 0, xPercent: -100, ease: "power3.inOut", duration: 0.75 },
      0
    );
    tl2.to(
      `${baseSelector} .menu-options .menu-option.two`,
      { autoAlpha: 0, xPercent: -100, ease: "power3.inOut", duration: 0.75 },
      0
    );
    tl2.to(
      `${baseSelector} .menu-options`,
      {
        autoAlpha: 0,
        xPercent: -100,
        duration: 0.75,
        ease: "power3.inOut",
        onComplete: () => {},
      },
      0
    );
    tl2.to(
      `${baseSelector} .tab-container`,
      {
        xPercent: 100,
        autoAlpha: 0,
        duration: 0.75,
        ease: "power3.inOut",
      },
      0
    );
    tl2.to(
      `${baseSelector} .overlay-bg`,
      { autoAlpha: 0, xPercent: -135, ease: "power3.inOut", duration: 0.4 },
      0
    );

    tl2.to(
      `${baseSelector} .content-overlay-content`,
      { autoAlpha: 0, x: 0, ease: "power3.inOut", duration: 0.75 },
      0
    );

    // Finally, hide the overlay and restore card opacity
    tl2.to(
      baseSelector,
      {
        autoAlpha: 0,
        ease: "power3.inOut",
        duration: 0.25,
        onComplete: () => {
          document
            .querySelectorAll(`${baseSelector} .menu-option`)
            .forEach((el) => el.classList.remove("active"));
          document
            .querySelectorAll(`${baseSelector} .content`)
            .forEach((el) => el.classList.remove("active"));
          document
            .querySelector(`${baseSelector} .next-btn`)
            .classList.remove("active-btn");
        },
      },
      0.4
    );
  }

  // Apply logic to each card
  cardWrap.forEach((card) => {
    const classes = ["retail", "healthcare", "manufacturing", "education"];
    classes.forEach((className) => {
      if (card.classList.contains(className)) {
        setInitialGSAP(className);
        card.addEventListener("click", () =>
          handleCardClick(className, cardWrap, menuOptionsContent, menuOptions)
        );
        document
          .querySelectorAll(`.tab-container.${className} .tab`)
          .forEach((tab) => {
            if (tab.classList.contains("retail")) {
              tab.addEventListener("click", () =>
                handleTabSwitch(
                  className,
                  "retail",
                  cardWrap,
                  menuOptionsContent,
                  menuOptions
                )
              );
            } else if (tab.classList.contains("healthcare")) {
              tab.addEventListener("click", () =>
                handleTabSwitch(
                  className,
                  "healthcare",
                  cardWrap,
                  menuOptionsContent,
                  menuOptions
                )
              );
            } else if (tab.classList.contains("manufacturing")) {
              tab.addEventListener("click", () =>
                handleTabSwitch(
                  className,
                  "manufacturing",
                  cardWrap,
                  menuOptionsContent,
                  menuOptions
                )
              );
            } else if (tab.classList.contains("education")) {
              tab.addEventListener("click", () =>
                handleTabSwitch(
                  className,
                  "education",
                  cardWrap,
                  menuOptionsContent,
                  menuOptions
                )
              );
            }
          });

      }
    });
  });

  gsap.utils.toArray("h1:not(.no-animate)").forEach(function (elem) {
    gsap.fromTo(
      elem,
      { opacity: 0.9, y: 10 },
      {
        y: 0,
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: elem,
          // markers: true,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.5,
        },
      }
    );
  });

  // Basic add class
  const activeobj = gsap.utils.toArray(".activate");
  // console.log(activeobj);
  activeobj.forEach((sec, i) => {
    ScrollTrigger.create({
      trigger: sec,
      start: "top 80%",
      end: "bottom 75%",
      toggleActions: "play none none none",
      onEnter: () => {
        sec.classList.add("activated");
      },
      onLeaveBack: () => {
        sec.classList.remove("activated");
      },
    });
  });

  const whiteBg = gsap.utils.toArray(".white-bg");
  // console.log(whiteBg);

  // Animated numbers
  $(".counts").each(function (index, element) {
    var count = $(this),
      zero = { val: 0 },
      num = count.data("number"),
      split = (num + "").split("."),
      decimals = split.length > 1 ? split[1].length : 0;

    gsap.to(zero, {
      val: num,
      duration: 2.5,
      scrollTrigger: {
        // markers: true,
        trigger: element,
        start: "bottom 85%",
        // end: "+=500"
      },
      ease: "power2.inOut",
      onUpdate: function () {
        count.text(zero.val.toFixed(decimals));
      },
    });
  });

  $(".menu button").on("click", function () {
    var content = $(this).attr("data-type");

    $(
      "button[data-type = " + content + "], div[data-type = " + content + "]"
    ).addClass("active");
    $(
      "button:not([data-type = " +
        content +
        "]), div:not([data-type = " +
        content +
        "])"
    ).removeClass("active");
  });

  function checkForWindowResize() {
    console.log(`Screen width: ${window.innerWidth}`);

    if (window.innerWidth < 1200) {
    } else {
      // Complete Journey Trigger
      $(".complete-journey button.main, .complete-journey h1.main").on(
        "click",
        function () {
          var content = $(this).attr("data-type");
          console.log(content);
          if ($("[data-type = " + content + "]").hasClass("reveal")) {
            $("[data-type = " + content + "]").removeClass("reveal");
          } else {
            $("[data-type = " + content + "]").addClass("reveal");
          }
          $(":not([data-type = " + content + "])").removeClass("reveal");

          // Highlight phase indicators
          if (content == "journey-discover" || content == "journey-define") {
            // console.log('check check journey');
            $(".phase.one").addClass("active");
            $(".phase.two").removeClass("active");
            $(".phase.three").removeClass("active");
          } else if (
            content == "journey-deploy" ||
            content == "journey-deliver"
          ) {
            $(".phase.two").addClass("active");
            $(".phase.one").removeClass("active");
            $(".phase.three").removeClass("active");
          } else if (content == "journey-evolve") {
            $(".phase.three").addClass("active");
            $(".phase.one").removeClass("active");
            $(".phase.two").removeClass("active");
          }

          // play animations
          if ($(".discover.main").hasClass("reveal")) {
            discoverAnim.play();
            console.log(discoverAnim);
            // console.log('discover play');
          } else {
            discoverAnim.pause();
            // console.log('discover pause');
          }

          if ($(".define.main").hasClass("reveal")) {
            defineAnim.play();
            // console.log('define play');
          } else {
            defineAnim.pause();
            // console.log('discover pause');
          }

          if ($(".deploy.main").hasClass("reveal")) {
            deployAnim.play();
            // console.log('deploy play');
          } else {
            deployAnim.pause();
            // console.log('discover pause');
          }

          if ($(".deliver.main").hasClass("reveal")) {
            deliverAnim.play();
            // console.log('deliver play');
          } else {
            deliverAnim.pause();
            // console.log('discover pause');
          }

          if ($(".evolve.main").hasClass("reveal")) {
            evolveAnim.play();
            // console.log('evolve play');
          } else {
            evolveAnim.pause();
            // console.log('discover pause');
          }
        }
      );
    }
  }

  window.addEventListener("resize", checkForWindowResize);

  $(".close-btn").on("click", function () {
    $(".drawer").removeClass("active");
    $(this).removeClass("active");
    $(".panel-menu button").removeClass("active");
  });

  // open and close the offcanvas menu
  const mediaQuery = "(min-width: 767px)";

  // Form spree stuff
  var form = document.getElementById("vj-form");

  async function handleSubmit(event) {
    var emailValue = document.getElementById("email").value;

    event.preventDefault();

    const url =
      "https://api.antideo.com/email/" +
      emailValue +
      "?apiKey=00141186c11e66b039314a190f284ecf";
    fetch(url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then(function (data) {
        // first check if email is good or not
        console.log(data);
        if (data.scam == false && data.spam == false) {
          document.getElementById("scamCheck").value = "Cleared Spam Check";
          console.log("cleared spam check");
        } else {
          document.getElementById("scamCheck").value = "Potential Spam";
          console.log("Spam");
        }

        if (data.free_provider == true) {
          console.log("this is a free email");
        } else {
          // Then fetch and submit the form
          var status = document.querySelector("#my-form-status");
          console.log(status);
          var data = new FormData(event.target);
          fetch(event.target.action, {
            method: form.method,
            body: data,
            headers: {
              Accept: "application/json",
            },
          })
            .then((response) => {
              console.log(response);
              if (response.ok) {
                var form = document.getElementById("vj-form");
                form.style.visibility = "hidden";
                status.innerHTML =
                  "Thank you for your submission. We will get in touch shortly.";
                form.reset();
              } else {
                response.json().then((data) => {
                  if (Object.hasOwn(data, "errors")) {
                    status.innerHTML = data["errors"]
                      .map((error) => error["message"])
                      .join(", ");
                  } else {
                    status.innerHTML =
                      "Oops! There was a problem submitting your form";
                  }
                });
              }
            })
            .catch((error) => {
              console.log(error);
              status.innerHTML =
                "Oops! There was a problem submitting your form";
            });
        } // End email provider check.
      })

      .catch(function (error) {
        console.log(error);
      });
  }
  form.addEventListener("submit", handleSubmit);

  let smoother = ScrollSmoother.create({
    smooth: 0.25, // seconds it takes to "catch up" to native scroll position
    effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
    smoothTouch: 0,
    normalizeScroll: true,
  });
});
